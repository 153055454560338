import React from "react";
import { Layout, SEO, Text, Image, AppLink, Button } from "@components";
import { useNotFoundPageData, useLogoData } from "@queries";

/* eslint react/prop-types: 0 */

const NotFoundPage = () => {
  const { meta, heading, link, linkText } = useNotFoundPageData();
  const { logo } = useLogoData();

  return (
    <Layout className="bg-gray-3">
      <SEO {...meta} />
      <div className="flex flex-col items-center justify-center flex-1 h-full">
        <div
          style={{ width: 100, height: 100 }}
          className="relative inline-block rounded-lg overflow-hidden mb-6"
        >
          <Image image={logo} objectFit="contain" />
        </div>

        <Text preset="h1" className="text-white">
          {heading}
        </Text>
        <AppLink to={link.url} target={link.target} className="mt-6">
          <Button color="orange" text={linkText} />
        </AppLink>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
